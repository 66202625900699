
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul{
  list-style-type: none;
}
a{color: #343434;text-decoration: none;}
a:link{color: #343434;text-decoration: none;}
a:active{color: #233e8b;text-decoration: none;}
a:visited{color: #343434;text-decoration: none;}
a.clear-default-link-style{
  color: inherit;text-decoration: none;
  &:link{color:inherit;text-decoration: none;}
  &:visited{color: inherit;text-decoration: none;}
}
html{
  background-color: #969696;
}
/* APP */
.App {
  color: #343434;
  font-size: 14px;
  background-color: #969696;
  // padding-bottom: 170px;
  min-height: 100vh;
}
.index{
  background-color: #ffffff;
  min-height: 100vh;
}
.cursor{
  cursor: url(../images/panda.svg), crosshair;
}
.main-gradient{
  background-image: radial-gradient(#fff1eb, #ace0f9 100%);
}
.container{
  padding-top: 72px;
  min-height: 100vh;
  background-color: #fff;
}
/* 旋转logo效果 */
.logo-box{
  position: absolute;
  top: -100px;
  left: 55px;
  width: 300px;
  height: 300px;
  padding: 12px;
  z-index: 33;
}
/* 运动的logo效果 */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.banner{
  height: calc(100vh - 112px);
  /* background-image: linear-gradient(to top, rgb(253, 219, 146) 0%, rgb(209, 253, 255) 100%);  */
  &.full_gradient.state-complete{
    height: 680px !important;
  }
}
.view-box {
  position: absolute;
  bottom: 138px;
  right: 0;
  width: 500px;
  height: 500px;
  z-index: 30;
  .pole-box{
    position: absolute;
    top: 0;
    left: 180px;
    width: 50px;
    height: 500px;
    background-color: #ffffff;
    border-radius: 25px 25px 3px 3px;
    box-shadow: 3px 3px 18px #e8ecef;
    z-index: 32;
  }
  .left-view {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
    height: 100px;
    color: #ffffff;
    background-color: #000000;
    border-radius: 50px 8px 8px 50px;
    box-shadow: -2px 1px 6px #1b1a17;
  }
  .left-view p{
    width: 100%;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 总体的渐变效果 */
.full_gradient {
  z-index: -1;
  position: fixed;
  height: 100vw !important;
  width: 100vh !important;
  left: 0;
  top: 0;
  border-radius: 50%;
  transform: scale(0);
  transform-origin: center;
  transition: transform 0.7s cubic-bezier(0.47, 0.04, 0.22, 0.92);
  background-color: #86939e;
  &.state-complete{
    transition: transform 0.7s cubic-bezier(0.47, 0.04, 0.22, 0.92), height 0.2s ease, width 0.2s ease;
    transform: none;
    border-radius: 0;
    height: 100vh !important;
    width: 100vw !important;
    left: 0 !important;
    top: 0 !important;
  }
}

/* 圆形块状效果 */
.circle{
  border-radius: 100%;
}

/* 常用宽度 */
.w-full{
  width: 100%;
}
/* 常用弹性盒子布局 */
.boxflex{
  display: flex;
  justify-content: space-between;
}
.flexcenter{
  display: flex;
  justify-content: center;
}
.flexalign-center{
  align-items: center;
}

/* 按钮效果 */
.base-button{
  display: inline-block;
  height: 28px;
  padding: 0 8px;
  color: #eda268;
  line-height: 28px;
  background-color: #125d98;
  letter-spacing: 1px;
  outline: 0px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
}
.base-button.default-button{
  background-color: transparent;
  border: 1px solid #d1d9d9;
}
.base-button.text-button{
  color: #eda268;
  border: 1px solid transparent;
  background-color: transparent;
}

/* 文字效果 */
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-justify{
  text-align: justify;
}
.text-grey1{
  color: #666666;
}
.text-grey2{
  color: #999999;
}

/* 展开折叠效果 */
.fold-box{
  display: flex;
  & .text{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: justify;
    line-height: 24px;
  }
  & .text::before{
    content: '';
    float: right;
    width: 0;
    height: 100%;
    margin-top: -20px;
  }
  .btn{
    float: right;
    clear: both;
    height: 20px;
    padding: 1px 4px;
    line-height: 20px;
    color: #233e8b;
  }
  .btn::after{
    content: '展开';
  }
  .exp:checked+.text .btn::after{
    content: '收起';
  }
  .exp:checked+.text .btn::before{
    visibility: hidden;
  }
  .exp:checked+.text{
    -webkit-line-clamp: 9999;
    max-height: none;
  }
  .exp{
    visibility: hidden;
    width: 0;
  }
}

/* 基础效果 */
.center-block{
  width: 1100px;
  margin: 0 auto;
}
.inline-block{
  display: inline-block;
}
.inline{
  display: inline;
}
.block{
  display: block;
}
.none{
  display: none;
}
.vertical-middle{
  vertical-align: middle;
}
.pos-relative{
  position: relative;
  top: 0;
  left: 0;
}

/* 设置滚动条样式 */
::-webkit-scrollbar{
  width: 2px;
  height: 2px;
  border-radius: 2px;
}
::-webkit-scrollbar-track{
  background-color: #deedf0;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb{
  width: 2px;
  height: 12px;
  border-radius: 2px;
  background-color: #343434;
}
.base-button a{
  display: inline-block;
  width: 100%;
  height: 100%;
}

/* 更多 */
.more-box{
  display: flex;
  & .text{
    width: 480px;
    height: 530px;
    overflow: auto;
    text-align: justify;
    line-height: 24px;
  }
  .btn{
    height: 20px;
    padding: 1px 4px;
    line-height: 20px;
    color: #233e8b;
  }
}
/* Icon  */
.base-icon{
  display: inline-block;
  height: 28px;
  line-height: 28px;
  svg{
    display: inline-block;
    vertical-align: text-bottom;
  }
}
.previewer {
  width: 50%;
  line-height: 36px;
  word-break: break-all;
  border-left: 1px solid #cccccc; 
  .html-previewer {
    height: calc(100% - 32px);
    overflow: auto;
  }
  & ul{
    padding-left: 20px;
    list-style-type: disc;
  }
  h1,h2,h3 {
    line-height: 56px;
  }
  h4,h5,h6 {
    line-height: 32px;
  }
  code {
    display: block;
    padding: 15px 12px;
    font-size: 12px;
    color: #333;
    word-break: normal;
    background-color: #f8f8f8;
    overflow-x: auto;
  }
  blockquote{
    border-left: 4px solid #c8c8c8;
    background-color: #f8f8f8;
    & p{
      padding-left: 8px;
    }
  }
  img {
    max-width: 100%;
  }
}
.clearfix::after{
  content: '';
  display: block;
  clear: both;
}
.message-box{
  position: absolute;
  top: 10%;
  left: calc(50% - 50px);
  color: #ffffff;
  font-size: 14px;
  background-color: rgba(0,0,0, .5);
  border-radius: 4px;
  z-index: 1000;
}
.msg-box{
  padding: 4px 48px;
}
.msg-box.msg-info::before{
  display: inline-block;
  width: 14px;
  height: 14px;
}
.base-icon.loading{
  height: auto;
  line-height: initial;
  margin-top: 30px;
  margin-left: 30px;
  animation: loadingrotate 1.2s infinite;
  transition: linear;
}
@keyframes loadingrotate {
  0% {
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.outer-theme{
  position: fixed;
  top: 270px;
  right: 8px;
  width: 78px;
  height: 78px;
  color: #f9b5a0;
  background-color: rgba(249,243,223, 0.3);
  border-radius: 39px;
  z-index: 37;
  .inner-theme{
    width: 68px;
    height: 68px;
    background-color: rgba(249,243,223, 0.5);
    border-radius: 34px;
    margin-top: 5px;
    margin-left: 5px;
    & [type=color] {
      visibility: hidden;
    }
    .theme-box{
      width: 60px;
      height: 60px;
      margin-left: 4px;
      margin-top: -23px;
      background-color: rgba(249,243,223, 0.8);
      border-radius: 30px;
    }
  }
  
}
.girl{
  position: fixed;
  bottom: 120px;
  left: 76px;
  width: 160px;
  height: 160px;
  z-index: 37;
  cursor: grab;
  img{
    width: 100%;
  }
  animation: sport 20s linear infinite;
}
@keyframes sport {
  0%{
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(-5deg);
  }
  50%{
    transform: rotate(0deg);
  }
  75%{
    transform: rotate(5deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
.fade-in{
  opacity: 0;
  transition: opacity 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-out{
  opacity: 1;
  transition: opacity 2s;
}
@keyframes fadeout{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.repeat-bg{
  width: 100%;
  background-repeat: repeat-x;
  background-position: top;
}
.flower1{
  height: 100px;
  background-image: url('../images/flower.svg');
}
.flower2{
  height: 20px;
  background-image: url('../images/flower2.svg');
}
.star{
  height: 20px;
  background-image: url('../images/star.svg');
}
.reed{
  height: 170px;
  background-image: url('../images/reed.svg');
  background-size: 20% 100%;
}
.reed-offset{
  margin-left: -44px;
}
.repeat-reed{
  margin-top: -170px;
}
.homebg-pic{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 120px;
  height: 200px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../images/flower3.svg');
  opacity: 0.3;
  transform: rotatex(180deg);
}
.text-none{
  font-size: 18px;
  color: #c3cbd6;
}
.none-data{
  width: 300px;
  height: 300px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('../images/nonedata.svg');
}
.base-bg{
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.girl1{
  background-image: url(../images/girl1.svg);
}
.girl2{
  background-image: url(../images/girl2.svg);
}
.flex-value{
  flex: 1;
}
.blank-none{
  padding: 64px;
}
.bidden,
.not-found{
  min-height: 100vh;
  background-color: #ffffff;
  overflow: hidden;
  img{
    display: block;
    width: 500px;
    height: 500px;
    margin: 80px auto;
  }
  .prompt-text{
    color: #999999;
    text-align: center;
  }
}
.fixed-bottom{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.base-input {
  width: 240px;
  height: 28px;
  padding: 0 8px;
  line-height: 28px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
}