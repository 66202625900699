.avator{
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 34px;
  text-align: center;
  border: 1px solid #cccccc;
  border-radius: 50px;
  img{
    vertical-align: middle;
  }
}
.right-bar{
  height: 48px;
  line-height: 48px;
}
.description{
  width: 480px;
  padding-top: 80px;
  .title{
    line-height: 48px;
  }
}
.navBar{
  width: 900px;
  height: 48px;
  line-height: 48px;
  & ul{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & li{
      width: 76px;
      text-align: center;
    }
  }
}
// tag-view
.tag-view {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  min-height: 150px;
  background-color: transparent;
  transform: rotate(270deg) translate(-42px);
  .tag-list {
    & li {
      width: 100%;
      text-align: left;
      list-style-type: none;
    }
    & .list-item{
      display: inline-block;
      width: 42px;
      height: 24px;
      margin: 4px 0;
      text-align: center;
      line-height: 24px;
      color: transparent;
      font-size: 14px;
      background-color: #ac0d0d;
      border-radius: 4px 12px 12px 4px;
      text-decoration: none;
    }
    & .list-item:hover {
      width: 100%;
      color: #f0c929;
    }
  }
}
.fix-header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  color: #343434;
  background: #FBF4E9;
  z-index: 35;
}
.next{
  position: absolute;
  bottom: 0;
  left: 0;
}
.previewer{
  width: 480px;
  height: 530px;
  padding: 12px;
  overflow: auto;
  text-align: justify;
  line-height: 24px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
}
.side-bar{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 330px;
  color: #ffffff;
  background-color: rgba(51,71,86, 0.8);
  transition: transform 1s;
  box-sizing: border-box;
  z-index: 36;
  &.visible{
    transform: translateX(0px);
  }
  &.hidden{
    transform: translateX(330px);
  }
  .side-header{
    height: 48px;
    line-height: 48px;
    .title{
      padding-left: 12px;
      font-size: 16px;
      color: #F0A500;
      font-weight: 800;
    }
    img{
      width: 48px;
      height: 48px;
      border-radius: 24px;
      vertical-align: middle;
    }
  }
  .personal-info{
    line-height: 48px;
    .label{
      display: inline-block;
      width: 80px;
      font-weight: 600;
      font-size: 16px;
    }
    .value{
      font-size: 14px;
    }
  }
}
.essay-content{
  min-height: calc(100vh - 60px);
  background-color: #ffffff;
  .footer {
    padding-top: 170px;
    overflow: hidden;
  }
  .content{  
    .poem{
      width: 680px;
    }
    .sentence {
      line-height: 24px;
    }
    .title{
      font-size: 16px;
      font-weight: 600;
    }
    .author{
      vertical-align: top;
      color: #9c9c9c;
    }
  }
}
.header{
  display: flex;
  justify-content: space-between;
}