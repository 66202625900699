.modal-mask{
  position: fixed;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  .modal-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 16px;
    background-color: #ffffff;
    .header{
      display: flex;
      padding: 4px 0px;
    }
    .title{
      width: 100%;
      text-align: center;
      color: #343434;
      line-height: 28px;
      font-weight: 900;
      letter-spacing: 1px;
    }
    .close {
      width: 28px;
      height: 28px;
      text-align: center;
    }
    .body{
      min-height: 160px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .footer{
      padding-bottom: 8px;
      padding-top: 8px;
    }
  }
}

.msg-box {
  padding: 4px 48px;
  .base-icon{
    height: 28px;
    line-height: 28px;
    margin-top: -4px;
    margin-right: 4px;
    vertical-align: middle;
  }
}
.loading-mask{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 34;
  .loading-box{
    position: absolute;
    top: calc(50% - 80px);
    left: calc( 50% - 80px);
    width: 160px;
    height: 160px;
    // background-color: rgba(55, 55, 55, 0.1);
    border-radius: 8px;
    z-index: 35;
    // opacity: 0.3;
  }
}